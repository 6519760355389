import { HOST_DOMAIN, HOST_URL, MASTER_ADMIN, USER_COOKIE } from './constants';

export const STAGE_TYPE_COUNT = "stage_type_count";
export const REASON_TYPE_COUNT = "reason_type_count";

export function setCompanyDomain(value) {
  localStorage.setItem("COMPANY_DOMAIN", value);
}
export function getCompanyDomain() {
  return localStorage.getItem("COMPANY_DOMAIN");
}

export function waitForElementToExist(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });
}

// date formate
export const FormatDate = (date) => {
  return date
    ?.toISOString()
    .replace(/T/, " ")
    .replace(/:00.000Z/, "");
};

// today date with formated
export const TodayDate = (daysToAdd = 0) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate);
  targetDate.setDate(currentDate.getDate() + daysToAdd);

  return targetDate
    .toISOString()
    .replace(/T/, " ")
    .replace(/:00.000Z/, "");

  // return new Date()
  //   .toISOString()
  //   .replace(/T/, " ")
  //   .replace(/:00.000Z/, "");
};

export const handleHtmlToBlob = async (html) => {
  const html2canvas = (await import('html2canvas')).default;
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.left = '-9999px';
  container.innerHTML = html;
  document.body.appendChild(container);
  const blob = await new Promise(resolve => {
    html2canvas(container, { useCORS: true }).then(canvas => {
      return canvas.toBlob(resolve, 'png', 1.0);
    });
  });
  const imageUrl = window.URL.createObjectURL(new Blob([blob]));
  setTimeout(() => {
    container.style.display = 'none';
  }, 500);
  return { imageUrl, blob };
}

export const returnSubstring = (str, num) => {
  if (!str) {
    return null;
  }
  else if (str.length > num) {
    return str.substring(0, num) + "..."
  }

  return str;
}

// to be used only when keys are in same order
export const areObjectsEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
//delete specific cookie
export const deleteCookie = (cookieName) => {
  document.cookie = `${cookieName}=;domain=.${HOST_DOMAIN};path=/;max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
};

//logout to base url
export const logoutAndRedirectToLogin = () => {
  const domain = getCompanyDomain();
  let loc = buildUrl(domain);
  let navigateUrl = `${loc}/login`;
  deleteCookie(USER_COOKIE);
  removeLocalStorageForUser();
  window.location.replace(navigateUrl);
};

export const logoutAndRedirectToMasterLogin = () => {
  const domain = getCompanyDomain();
  let loc = buildUrl(domain);
  let navigateUrl = `${loc}/${MASTER_ADMIN}/login`;
  removeLocalStorageForMasterAdmin();
  window.location.replace(navigateUrl);
};


export const buildUrl = (domain) => {
  let url = `${HOST_URL}`;
  if (domain) {
    url = `${domain}.${url}`;
  }
  return `${window.location.protocol}//${url}`
}

//setLocalStorageData
export const setLocalStorageData = (user_session) => {
  localStorage.setItem("user_email", user_session?.user?.email);
  localStorage.setItem("login_id", user_session?.login_id);
  localStorage.setItem("token", user_session?.token);
  localStorage.setItem(
    "user_info",
    JSON.stringify(user_session?.user_info)
  );
  setCompanyDomain(user_session?.domain);
};

export const removeLocalStorageForUser = () => {
  localStorage.removeItem("user_email");
  localStorage.removeItem("login_id");
  localStorage.removeItem("token");
  localStorage.removeItem("user_info");
  localStorage.removeItem("COMPANY_DOMAIN");
}

export const removeLocalStorageForMasterAdmin = () => {
  localStorage.removeItem("master_token");
  localStorage.removeItem("master_info");
}

//set Cookie data
export const setCookie = (cookieName, payload) => {
  document.cookie = `${cookieName}=${JSON.stringify(payload)};domain=.${HOST_DOMAIN};path=/;`;
}

export const redirectToDashboard = async (domain, response, user) => {
  let sendPayload = {
    token: response?.token,
    login_id: response?.data?.data?.id,
    user_info: response?.data?.data?.attributes,
    domain: domain,
  };
  if (user) {
    sendPayload = { ...sendPayload, user_email: user?.email };
  }
  let url = buildUrl(domain);
  url = `${url}/dashboard`;
  setCookie(USER_COOKIE, sendPayload);
  window.location.href = url;
};

export const compareStrings = (str1, str2) => {
  return str1 === str2;
}

export const isMasterAdminRoute = () => {
  return window.location.pathname.includes(`/${MASTER_ADMIN}/`);
}

export const isOnValidDomain = (domain) => {
  let domainInStorage = domain;
  let domainInUrl = window.location.host.split('.')[0];

  return isMasterAdminRoute() || !domainInStorage || compareStrings(domainInUrl, domainInStorage);
}

export const redirectToValidDomain = (domain) => {
  let url = buildUrl(domain);
  url = `${url}${window.location.pathname}${window.location.search}`;
  window.location.href = url;
}

export const needToSetLocalStorage = (cookie) => {
  const localDomain = getCompanyDomain();
  const localToken = localStorage.getItem("token");

  return cookie.domain !== localDomain || cookie.token !== localToken
}