import { Navigate } from "react-router-dom";
import { buildUrl } from "./utils";
import { useEffect } from "react";

export const Authenticate = ({ children }) => {

    const isAuthenticated = () => !!(localStorage.getItem("token"));

    const redirectToDashboard = () => {
        let domain = localStorage.getItem("domain");
        const url = buildUrl(domain);
        window.location.href = `${url}/dashboard`;
    };

    useEffect(() => {
        if (isAuthenticated()) {
            redirectToDashboard();
        } 
    }, [])

    return children;
};

export const MasterAuthenticate = ({ children }) => {
    const isAuthenticated = () => !!(localStorage.getItem("master_token"))

    if (isAuthenticated()) {
        return <Navigate to='/master-admin/master-template' replace />
    }

    return children
}